import React, { useState, useEffect, useCallback } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getYear, getMonth, subYears } from "date-fns";
// import AWS from 'aws-sdk';
import AddressFields from "./AddressFields";

const loanPurposes = [
  "Acquiring a business",
  "Business/headcount expansion",
  "Marketing",
  "Paying wages",
  "Paying taxes",
  "Purchasing commercial property",
  "Purchasing equipment",
  "Purchasing stock",
  "Refinancing existing debt",
  "Renovating property",
  "Shareholder buyout",
  "Other",
];

const questions = [
  { text: "How much would you like to borrow?", type: "number" },
  {
    text: "What do you plan to use the funds for?",
    type: "dropdown",
    options: loanPurposes,
  },
  { text: "What is your company's current annual revenue?", type: "number" },
  {
    text: "When would you like the funds?",
    type: "multiple",
    options: ["Immediately", "Within a week", "Within a month"],
  },
  { text: "What is your date of birth?", type: "date" },
  { text: "What is your residential address?", type: "address" },
  {
    text: "What is your residential status?",
    type: "multiple",
    options: ["Homeowner", "Renter", "Living with family", "Other"],
  },
  {
    text: "Thank you for those details. Your application is now with our credit team and we will be in touch to discuss your application shortly.",
    type: "file",
  }, // New question for document upload
];

const keyMapping = {
  question_0: "loanAmount",
  question_1: "loanPurpose",
  question_2: "currentAnnualRevenue",
  question_3: "loanAcquisitionTimeline",
  question_4: "dateOfBirth",
  question_5: "address",
  question_6: "residentialStatus",
  question_7: "fileUpload",
};

const avatarUrl = "clearcap_logo.png"; // Placeholder URL for avatar image

const ChatPage = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState("");
  const [userInput, setUserInput] = useState("");
  const [answers, setAnswers] = useState({});
  const [typing, setTyping] = useState(true);
  const [shouldType, setShouldType] = useState(true); // New state to control typing effect
  const [uploadedFiles, setUploadedFiles] = useState([]); // State to store uploaded filenames
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [isAddressValid, setIsAddressValid] = useState(false);
  const [disclaimer, setDisclaimer] = useState(false);
  const [displayedDisclaimer, setDisplayedDisclaimer] = useState(false);

  //   const [file, setFile] = useState(null); // New state for file upload

  useEffect(() => {
    const storedAnswers = {};
    Object.keys(keyMapping).forEach((key, index) => {
      const userParams = JSON.parse(localStorage.getItem("userParams"));
      const storedValue = userParams[keyMapping[key]];
      if (storedValue) {
        storedAnswers[index] = storedValue;
      }
    });
    setAnswers(storedAnswers);
  }, []);

  useEffect(() => {
    if (shouldType) {
      const typeQuestion = async () => {
        setTyping(true);
        const question = questions[currentQuestionIndex].text;
        for (let i = 0; i <= question.length; i++) {
          setCurrentQuestion(question.substring(0, i));
          await new Promise((resolve) => setTimeout(resolve, 25));
        }
        setTyping(false);
        if (answers[currentQuestionIndex] !== undefined) {
          if (questions[currentQuestionIndex].type === "date") {
            setUserInput(new Date(answers[currentQuestionIndex]));
          } else {
            setUserInput(answers[currentQuestionIndex]);
          }
        }
      };
      typeQuestion();
      setShouldType(false);
    } else {
      setCurrentQuestion(questions[currentQuestionIndex].text);
      if (answers[currentQuestionIndex] !== undefined) {
        if (questions[currentQuestionIndex].type === "date") {
          setUserInput(new Date(answers[currentQuestionIndex]));
        } else {
          setUserInput(answers[currentQuestionIndex]);
        }
      }
    }
  }, [currentQuestionIndex, answers, shouldType]);

  //   const handleAnswer = useCallback(() => {
  //     if (questions[currentQuestionIndex].type === 'file' || (userInput !== "" && userInput !== null)) {
  //       setAnswers((prevAnswers) => ({ ...prevAnswers, [currentQuestionIndex]: userInput }));
  //       saveToLocalStorage(keyMapping[`question_${currentQuestionIndex}`], userInput);
  //       setUserInput("");
  //       if (currentQuestionIndex < questions.length - 1) {
  //         console.log("current question index:", currentQuestionIndex);
  //         setCurrentQuestionIndex(currentQuestionIndex + 1);
  //         setShouldType(true); // Trigger typing effect for the next question
  //       } else {
  //         // alert("Form completed!");
  //         // console.log('redireting...')
  //         const currentLocation = window.location.href;
  //         window.location.href = currentLocation;
  //       }
  //     }
  //   }, [userInput, currentQuestionIndex]);

  const handleAnswer = useCallback(() => {
    let finalAnswer = userInput;

    if (currentQuestionIndex === 0) {
      // Check if the question index is 0 (loanAmount)
      const loanAmount = parseFloat(finalAnswer);
      if (loanAmount < 5000) {
        alert("The minimum loan amount is £5,000.");
        return;
      }
      if (loanAmount > 1000000) {
        alert("The maximum loan amount is £1,000,000.");
        return;
      }
    }

    if (currentQuestionIndex === 1 && additionalInfo) {
      // Check if the question index is 1 (loanPurpose) and additional info is present
      finalAnswer = `${userInput}: ${additionalInfo}`;
    }

    if (
      questions[currentQuestionIndex].type === "file" ||
      (finalAnswer !== "" && finalAnswer !== null)
    ) {
      setAnswers((prevAnswers) => ({
        ...prevAnswers,
        [currentQuestionIndex]: finalAnswer,
      }));
      saveToLocalStorage(
        keyMapping[`question_${currentQuestionIndex}`],
        finalAnswer
      );
      setUserInput("");
      setAdditionalInfo(""); // Clear the additional info state
      if (currentQuestionIndex < questions.length - 1) {
        console.log("current question index:", currentQuestionIndex);
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        if (currentQuestionIndex === 3 && !displayedDisclaimer) {
          setDisclaimer(true);
          setDisplayedDisclaimer(true);
        }
        setShouldType(true); // Trigger typing effect for the next question
      } else {
        // alert("Form completed!");
        const currentLocation = window.location.href;
        window.location.href = currentLocation;
      }
    }
  }, [userInput, currentQuestionIndex, additionalInfo]);

  const isNextButtonDisabled = useCallback(() => {
    if (questions[currentQuestionIndex].type === "file") {
      return false;
    }
    return (
      userInput === "" ||
      userInput === null ||
      (currentQuestionIndex === 5 && !isAddressValid)
    );
  }, [userInput, currentQuestionIndex, isAddressValid]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter" && !isNextButtonDisabled()) {
        handleAnswer();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleAnswer, isNextButtonDisabled]);

  const saveToLocalStorage = (key, value) => {
    console.log("saving to local storage:", key, value);
    let userParams = JSON.parse(localStorage.getItem("userParams")) || {};

    if (key === "address") {
      // Convert the address object to a string
      const filteredAddress = Object.fromEntries(
        Object.entries(value).filter(([k, v]) => v !== "")
      );
      const formatted_address_str = Object.values(filteredAddress).join(", ");
      // Save the string-formatted address
      userParams[key] = formatted_address_str;
      // Save the original address object
      userParams["addressObject"] = JSON.stringify(value);
    } else {
      userParams[key] = value;
    }

    localStorage.setItem("userParams", JSON.stringify(userParams));
  };

  const handleFileUploadProxy = async (e) => {
    const files = Array.from(e.target.files);
    const userIdentifier = localStorage.getItem("userIdentifier");

    if (files.length && userIdentifier) {
      const formData = new FormData();
      formData.append("userIdentifier", userIdentifier);
      files.forEach((file) => {
        formData.append("files", file);
      });

      try {
        const response = await fetch("/api/uploadFile", {
          method: "POST",
          body: formData,
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        console.log("Result:", result); // Properly printing the JSON object
        setUploadedFiles((prevFiles) => [
          ...prevFiles,
          ...result.uploadedFiles,
        ]);
        setAnswers({ ...answers, [currentQuestionIndex]: true });
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  const handleRadioButtonAnswer = (answer) => {
    setAnswers({ ...answers, [currentQuestionIndex]: answer });
    saveToLocalStorage(keyMapping[`question_${currentQuestionIndex}`], answer);
    setUserInput(answer);
    // handleAnswer();
  };

  const handleBack = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      setShouldType(true); // Trigger typing effect for the previous question

      const prevQuestionType = questions[currentQuestionIndex - 1].type;
      const prevAnswer = answers[currentQuestionIndex - 1];

      if (prevQuestionType === "date" && prevAnswer) {
        setUserInput(new Date(prevAnswer));
      } else {
        setUserInput(prevAnswer);
      }
    }
  };

  const renderInputField = () => {
    const question = questions[currentQuestionIndex];
    console.log("question index: ", currentQuestionIndex);
    const years = Array.from(
      { length: getYear(new Date()) - 1900 + 1 },
      (v, k) => k + 1900
    );
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // const question = questions[currentQuestionIndex];
    switch (question.type) {
      case "number":
        return (
          <span>
            £
            <input
              type={question.type}
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              className="border-b-2 text-base sm:text-xl p-2 focus:outline-none bg-white"
              min="5000" // Set the minimum amount here
            />
          </span>
        );
      case "text":
        return (
          <input
            type={question.type}
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            className="border-b-2 text-base sm:text-xl p-2 focus:outline-none bg-white"
          />
        );
      case "date":
        const parsedDate = userInput ? new Date(userInput) : null;
        // console.log(parsedDate);
        return (
          <DatePicker
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              // <div
              //   style={{
              //     margin: 10,
              //     display: "flex",
              //     justifyContent: "center",
              //   }}
              // >
              <div className="flex justify-center space-x-2">
                <button
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                >
                  {"<"}
                </button>
                <select
                  value={getYear(date)}
                  onChange={({ target: { value } }) => changeYear(value)}
                  className="border text-base sm:text-xl"
                >
                  {years.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <select
                  value={months[getMonth(date)]}
                  onChange={({ target: { value } }) =>
                    changeMonth(months.indexOf(value))
                  }
                  className="border text-base sm:text-xl"
                >
                  {months.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <button
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                >
                  {">"}
                </button>
              </div>
            )}
            selected={parsedDate}
            maxDate={subYears(new Date(), 18)}
            onChange={(date) => {
              const formattedDate = date.toISOString().split("T")[0]; // Format the date to YYYY-MM-DD
              setUserInput(formattedDate);
            }}
            className="border-b-2 text-base sm:text-xl p-2 focus:outline-none bg-white"
          />
        );
      case "multiple":
        return (
          <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
            {question.options.map((option, index) => (
              <label
                key={index}
                className="flex items-center space-x-2 border rounded p-2 hover:bg-gray-200"
              >
                <input
                  type="radio"
                  value={option}
                  checked={userInput === option}
                  onChange={(e) => handleRadioButtonAnswer(e.target.value)}
                  className="form-radio text-base sm:text-xl"
                />
                <span className="text-base sm:text-xl">{option}</span>
              </label>
            ))}
          </div>
        );

      case "dropdown":
        return (
          <div className="flex flex-col space-y-8">
            <select
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              className="border-b-2 text-base sm:text-xl p-2 focus:outline-none bg-white"
            >
              <option value="" disabled>
                Select an option
              </option>
              {question.options.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
            {userInput && (
              <input
                type="text"
                placeholder="Add more information (optional)"
                value={additionalInfo}
                onChange={(e) => setAdditionalInfo(e.target.value)}
                className="border-b-2 text-base sm:text-xl p-2 focus:outline-none bg-white mt-2"
              />
            )}
          </div>
        );
      case "file":
        return (
          <div>
            <div className="border border-gray-300 rounded-lg p-4 mt-4 mb-8">
              <p className="text-xl font-semibold">
                Please upload supporting documents to fast track your
                application.
              </p>
              <p className="text-base sm:text-lg">
                Lenders often require documents for underwriting. These are
                typically:
              </p>
              <br></br>
              <ul className="list-disc pl-8 sm:pl-12 text-base sm:text-lg">
                <li>
                  <b>Last six months' bank statements</b>
                </li>
                <li>
                  <b>Recent full filed accounts including P&L</b>
                </li>
              </ul>
              <br></br>
              <p className="text-base sm:text-lg">
                Please upload these documents if you have them available.
                Otherwise we can continue without them for now.
              </p>
            </div>

            <input
              type="file"
              multiple
              accept="application/pdf"
              onChange={handleFileUploadProxy}
              className="border-b-2 text-base sm:text-xl p-2 focus:outline-none bg-white"
            />
            <div className="mt-2">
              {Array.from(
                new Set(uploadedFiles.map((file) => file.split("/").pop()))
              ).map((fileName, index) => (
                <div key={index} className="text-sm text-gray-700">
                  &#9989; {fileName}
                </div>
              ))}
            </div>
          </div>
        );
      case "address":
        return (
          <AddressFields
            userInput={userInput}
            setUserInput={setUserInput}
            setIsAddressValid={setIsAddressValid}
          />
        );
      default:
        return null;
    }
  };

  const renderNextButtonLabel = () => {
    if (questions[currentQuestionIndex].type === "file") {
      return uploadedFiles.length > 0
        ? "Submit application"
        : "Next";
    }
    return "Next";
  };

  return (
    <div className="relative flex flex-col items-center min-h-screen bg-gray-100 p-4 sm:p-6 lg:p-8 m-4 sm:m-6 lg:m-16">
      {disclaimer ? (
        <>
          <div className="text-xl text-center font-bold  ">
            Thanks for providing information on your business and funding
            requirement. We need to collect some personal details from you so
            that Lenders can process a soft credit search as part of your
            application to provide you offers. This will not impact your credit
            file.
          </div>
          <button
            className="p-2 bg-consilium-primary  mt-8 text-black text-base sm:text-xl rounded"
            onClick={() => {
              setDisclaimer(false);
            }}
          >
            Continue
          </button>
        </>
      ) : (
        <div className="relative flex flex-col items-center sm:flex-row sm:items-center mb-8">
          <img
            src={avatarUrl}
            alt="Avatar"
            className="w-12 h-12 sm:w-16 sm:h-16 lg:w-20 lg:h-20 border border-consilium-primary rounded-full mb-4 sm:mb-0 sm:mr-4"
          />
          <div className="text-xl sm:text-2xl lg:text-3xl font-bold text-center sm:text-left flex items-center">
            {currentQuestion}
          </div>
        </div>
      )}
      {!typing && !disclaimer && (
        <div className="top-2/3 w-full flex flex-col items-center">
          {renderInputField()}
          <div className="mt-8 flex sm:flex-row space-x-8">
            {currentQuestionIndex !== 0 && (
              <button
                onClick={handleBack}
                className="mr-0 sm:mr-4 mb-4 sm:mb-0 p-2 bg-gray-500 text-black text-base sm:text-xl rounded"
              >
                &#8592;
              </button>
            )}
            {!isNextButtonDisabled() && (
              <button
                onClick={handleAnswer}
                className={`p-2 ${
                  isNextButtonDisabled()
                    ? "bg-gray-300"
                    : "bg-consilium-primary"
                } text-black border border-[#337152] sm:text-xl rounded`}
                disabled={isNextButtonDisabled()}
              >
                {renderNextButtonLabel()} &#8594;
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatPage;
